export const eventsList = [
    {
        'id': 'ssm',
        'name': "Satsang Suhrad Milan",
        'displayName': "Satsang Suhrad Milan",
        'description': "Annual Karyakar Appreciation Sabha (Only for invited guests)",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'eventDays': ['Friday, January 19th - 5:30 PM'],
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpSSM',
            'eventId': 'ssm',
            'eventName': 'Satsang Suhrad Milan - 2024',
            'displayName': 'Satsang Suhrad Milan',
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'time': '5:30 PM - 9:00 PM'
        },
        'hideList': true,
        'active': true
    },

    // Student Sabha - IIT
    {
        'id': 'student-sabha',
        'name': "Student Sabha - September",
        'displayName': "Student Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'Kings Room, Pershing Magnet School, 3200 S Calumet Ave, Chicago, IL 60616',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'student-sabha-sep',
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'Kings Room, Pershing Magnet School, 3200 S Calumet Ave, Chicago, IL 60616',
            'date': 'Thursday, September 19th, 2024',
            'time': '7:00 PM - 9:00 PM',
            'eventDays': ['Thursday September 19th - 7:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Student Sabha - UIC
    {
        'id': 'uic-student-sabha',
        'name': "Student Sabha - September",
        'displayName': "Student Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'Whitney M. Young Magnet High School, 211 S Laflin St, Chicago, IL 60607',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'uic-student-sabha-sep',
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'Whitney M. Young Magnet High School, 211 S Laflin St, Chicago, IL 60607',
            'date': 'Thursday, September 12th, 2024',
            'time': '7:00 PM - 9:00 PM',
            'eventDays': ['Thursday September 12th - 7:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Hindi Sabha
    {
        'id': 'hindi-sabha',
        'name': "Hindi Sabha - September",
        'displayName': "Hindi Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'hindi-sabha-sep',
            'eventName': 'Hindi Sabha',
            'displayName': 'Hindi Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'date': 'Sunday, September 8th, 2024',
            'time': '4:00 PM - 5:30 PM',
            'eventDays': ['Sunday September 8th - 4:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Samarpan Sabha
    {
        'id': 'samarpan-sabha',
        'name': "Guru Bhakti Argya",
        'displayName': "Guru Bhakti Argya",
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpSamarpan',
            'eventId': 'samarpan-sabha',
            'eventName': 'Guru Bhakti Argya',
            'displayName': 'Guru Bhakti Argya',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'eventDays': ['Thursday, September 12th - 7:00 PM'],
        },
        'hideList': true,
        'active': true
    },

    // Chicago 20 Main
    {
        'id': 'chicago20',
        'name': "Chicago Mandir 20th Patotsav",
        'displayName': "Chicago Mandir 20th Patotsav Mahapuja",
        'description': "Pramukh Swami Maharaj inspired thousands through his deep spirituality, humility and dedication to service. Pramukh Swami Maharaj’s birthday will be celebrated in a special event by women for women on December 17, 2023.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpMultiEvent',
            'eventId': 'chicago20',
            'eventName': 'Chicago Mandir 20th Anniversary Celebrations',
            'displayName': 'Chicago Mandir 20th Anniversary Celebrations',
            'description': "Fill out form below to rsvp for the events. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103'
        },
        'hideList': true,
        'active': false
    },

    // Multi Event
    {
        'id': 'chicago20-patotsav',
        'name': "Patotsav Mahapuja",
        'displayName': "Patotsav Mahapuja",
        'description': "Participate in the twentieth patotsav of the BAPS Shri Swaminarayan Mandir through a special māhāpujā - prayers for well-being and peace with Pujya Swāmis. Following the māhāpujā ceremony, devotees will have a chance to offer ārti, darshan and abhishek.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Thursday, August 8th',
        'time': '4:00 PM - 7:00 PM',
        'eventStartDateTime': '2024-8-8T16:00',
        'eventEndDateTime': '2024-8-8T19:00',
        'image': 'Chicago20Patotsav.JPG',
        'imageURL': 'https://chicagomandir.org/static/media/Chicago20Patotsav.d03225be.JPG',
        'needRsvp': {
            'text': 'RSVP Now'
        },
        'formData': {
            'formName': 'rsvpMultiEvent',
            'eventId': 'chicago20',
            'eventName': 'Chicago Mandir 20th Anniversary Celebrations',
            'displayName': 'Chicago Mandir 20th Anniversary Celebrations',
            'description': "Fill out form below to rsvp for the events. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103'
        },
        'active': false
    },
    {
        'id': 'chicago20-eternalgift',
        'name': "Our Eternal Gift",
        'displayName': "Our Eternal Gift",
        'description': "A special program by women, for women. This program will dive into the stories of the women who contributed to building the Mandir and how this sacred space has impacted their lives.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Saturday, August 10th',
        'time': '3:00 PM - 5:00 PM',
        'eventStartDateTime': '2024-8-10T15:00',
        'eventEndDateTime': '2024-8-10T17:00',
        'image': 'Chicago20EternalGift.JPG',
        'imageURL': 'https://chicagomandir.org/static/media/Chicago20EternalGift.48667f46.JPG',
        'needRsvp': {
            'text': 'RSVP Now'
        },
        'formData': {
            'formName': 'rsvpMultiEvent',
            'eventId': 'chicago20',
            'eventName': 'Chicago Mandir 20th Anniversary Celebrations',
            'displayName': 'Chicago Mandir 20th Anniversary Celebrations',
            'description': "Fill out form below to rsvp for the events. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103'
        },
        'active': false
    },
    {
        'id': 'chicago20-kirtanaradhana',
        'name': "More Mandir Aj Badhai",
        'displayName': "More Mandir Aj Badhai",
        'description': "Join us for a special musical tribute to reminisce the memories of building the Mandir. This unique kirtan ārādhanā will dive into the kirtans and bhajans that have a close history with the mandir.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Saturday, August 10th',
        'time': '6:45 PM - 9:00 PM',
        'eventStartDateTime': '2024-8-10T18:45',
        'eventEndDateTime': '2024-8-10T21:00',
        'image': 'Chicago20KirtanAradhana.JPG',
        'imageURL': 'https://chicagomandir.org/static/media/Chicago20KirtanAradhana.ea3db040.JPG',
        'needRsvp': {
            'text': 'RSVP Now'
        },
        'formData': {
            'formName': 'rsvpMultiEvent',
            'eventId': 'chicago20',
            'eventName': 'Chicago Mandir 20th Anniversary Celebrations',
            'displayName': 'Chicago Mandir 20th Anniversary Celebrations',
            'description': "Fill out form below to rsvp for the events. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103'
        },
        'active': false
    },
    {
        'id': 'chicago20-storyofstones',
        'name': "Story of the Stones",
        'displayName': "Story of the Stones: Finale Program",
        'description': "Join us in the finale celebrations of the 20th Anniversary of the BAPS Shri Swaminarayan Mandir presented by the Youths of BAPS. We will go through time in discovering the values that the Mandir has inspired over the last twenty years.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Sunday, August 11th',
        'time': '4:00 PM - 6:30 PM',
        'eventStartDateTime': '2024-8-11T16:00',
        'eventEndDateTime': '2024-8-11T18:30',
        'image': 'Chicago20StoryofStones.JPG',
        'imageURL': 'https://chicagomandir.org/static/media/Chicago20StoryofStones.386dac3e.JPG',
        'needRsvp': {
            'text': 'RSVP Now'
        },
        'formData': {
            'formName': 'rsvpMultiEvent',
            'eventId': 'chicago20',
            'eventName': 'Chicago Mandir 20th Anniversary Celebrations',
            'displayName': 'Chicago Mandir 20th Anniversary Celebrations',
            'description': "Fill out form below to rsvp for the events. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103'
        },
        'active': false
    },


    // Sample Event
    // {
    //     'id': 'psmjj',
    //     'name': "Pramukh Swami Maharaj Birthday - Mahila Celebration 2",
    //     'displayName': "Pramukh Swami Maharaj Birthday - Mahila Celebration 2",
    //     'description': "Pramukh Swami Maharaj inspired thousands through his deep spirituality, humility and dedication to service. Pramukh Swami Maharaj’s birthday will be celebrated in a special event by women for women on December 17, 2023.",
    //     'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
    //     'date': 'Sunday, December 17th',
    //     'time': '4:00 PM - 6:00 PM',
    //     'eventStartDateTime': '2024-12-18T16:00',
    //     'eventEndDateTime': '2024-12-18T18:00',
    //     'image': 'PSMMahilaSamaiyo.jpeg',
    //     'imageURL': 'https://www.baps.org/Data/Sites/1/Media/LocationImages/upcomingevents/WebImages/pramukh_swami_maharaj_007.jpg',
    //     'eventDays': ['Sunday, December 17th - 12:00 PM'],
    //     'needRsvp': {
    //         'text': 'Register Now'
    //     },
    //     'formData': {
    //         'formName': 'rsvpEvent',
    //         'eventId': 'psmjj',
    //         'eventName': 'Pramukh Swami Maharaj Birthday - Mandir',
    //         'displayName': 'Pramukh Swami Maharaj Birthday',
    //         'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
    //         'time': '4:00 PM - 6:00 PM',
    //         'description': 'Join us for a cordial gathering of the Hindu community in a unique celebration of unity and harmony. Let us connect to exchange ideas and inspire collaboration. Let us grow together.',
    //         'eventDays': ['Sunday January 7th - 4:00 PM', 'Sunday January 8th - 4:00 PM'],
    //     },
    //     'active': false
    // },
];